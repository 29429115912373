































import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OLocationsList: () =>
      import(
        "@/components/organisms/globalTools/location/o-locations-list.vue"
      ),
  },

  setup() {
    const state = reactive({
      items: [
        { value: "list", text: "Lista" },
        { value: "grid", text: "Kafelki" },
      ],
      select: "list",
      search: "",
    });

    return { state };
  },
});
